<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar
          active="myprofile"
          :user="body.userName"
          :image="body.image"
        />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile myproffsf mt-5 pt-4">
          <div class="header-profile">
            <h2>{{ $t("Edit my account") }}</h2>
          </div>
          <div class="col-lg-6 col-md-12 from-all">
            <Card>
              <template #content>
                <form @submit.prevent="save()">
                  <div class="text-center mb-5" style="height: 163px">
                    <div
                      style="display: inline-block; width: 163px; height: 163px"
                    >
                      <div class="ovvvoff" @click="$refs.file1.click()">
                        <svg
                        style="margin-top: 39%;"
                          width="31"
                          height="27"
                          viewBox="0 0 31 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 5.83333V7.08333C9.15661 7.08333 9.53781 6.88556 9.77195 6.55314L8.75 5.83333ZM10.6485 3.13799L9.62651 2.41818L9.62651 2.41818L10.6485 3.13799ZM20.3515 3.13799L21.3735 2.41818V2.41818L20.3515 3.13799ZM22.25 5.83333L21.2281 6.55314C21.4622 6.88556 21.8434 7.08333 22.25 7.08333V5.83333ZM18.3 15.4167C18.3 16.7795 17.112 18 15.5 18V20.5C18.3615 20.5 20.8 18.288 20.8 15.4167H18.3ZM15.5 18C13.888 18 12.7 16.7795 12.7 15.4167H10.2C10.2 18.288 12.6385 20.5 15.5 20.5V18ZM12.7 15.4167C12.7 14.0538 13.888 12.8333 15.5 12.8333V10.3333C12.6385 10.3333 10.2 12.5454 10.2 15.4167H12.7ZM15.5 12.8333C17.112 12.8333 18.3 14.0538 18.3 15.4167H20.8C20.8 12.5454 18.3615 10.3333 15.5 10.3333V12.8333ZM9.77195 6.55314L11.6704 3.85779L9.62651 2.41818L7.72805 5.11353L9.77195 6.55314ZM12.895 3.25H18.105V0.75H12.895V3.25ZM19.3296 3.8578L21.2281 6.55314L23.2719 5.11353L21.3735 2.41818L19.3296 3.8578ZM18.105 3.25C18.6195 3.25 19.0734 3.49402 19.3296 3.8578L21.3735 2.41818C20.6282 1.36005 19.396 0.75 18.105 0.75V3.25ZM11.6704 3.8578C11.9266 3.49402 12.3805 3.25 12.895 3.25V0.75C11.604 0.75 10.3718 1.36005 9.62651 2.41818L11.6704 3.8578ZM27.75 10.9444V19.8889H30.25V10.9444H27.75ZM23.6 23.75H7.4V26.25H23.6V23.75ZM3.25 19.8889V10.9444H0.75V19.8889H3.25ZM7.4 23.75C5.04237 23.75 3.25 21.9575 3.25 19.8889H0.75C0.75 23.4659 3.79295 26.25 7.4 26.25V23.75ZM27.75 19.8889C27.75 21.9575 25.9576 23.75 23.6 23.75V26.25C27.207 26.25 30.25 23.4659 30.25 19.8889H27.75ZM23.6 7.08333C25.9576 7.08333 27.75 8.87588 27.75 10.9444H30.25C30.25 7.36743 27.207 4.58333 23.6 4.58333V7.08333ZM7.4 4.58333C3.79295 4.58333 0.75 7.36743 0.75 10.9444H3.25C3.25 8.87588 5.04237 7.08333 7.4 7.08333V4.58333ZM7.4 7.08333H8.75V4.58333H7.4V7.08333ZM23.6 4.58333H22.25V7.08333H23.6V4.58333Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <img
                        class="from-img"
                        v-if="body.image"
                        :src="$baseUploadURL + body.image"
                        style="width: 163px; height: 163px; border-radius: 50%"
                        @click="$refs.file1.click()"
                      />
                      <img
                        class="from-img"
                        v-else
                        style="width: 163px; height: 163px; border-radius: 50%"
                        src="/images/user.png"
                        @click="$refs.file1.click()"
                      />
                      <input
                        type="file"
                        ref="file1"
                        style="display: none"
                        accept="image/*"
                        @change="addImage1"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >{{ $t("name") }}
                    </label>
                    <input
                      type="name"
                      v-model="body.userName"
                      class="form-control"
                      id="exampleInputEmail1"
                      :placeholder="$t('name')"
                    />

                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("E-mail") }}
                      </label>
                      <input
                        type="email"
                        v-model="body.email"
                        class="form-control"
                        id="exampleInputEmail1"
                        :placeholder="$t('E-mail')"
                      />
                      <div id="emailHelp" class="form-text"></div>
                    </div>
                     <div class="mb-3">
                      <label for="" class="form-label">
                        {{ $t("phone") }}
                      </label>
                      <input
                        type="tel"
                        v-model="body.phone"
                        class="form-control"
                        id=""
                        :placeholder="$t('phone')"
                      />
                      <div id="emailHelp" class="form-text"></div>
                    </div>
                    <div class="mb-5 pt-5">
                      <button
                        type="submit"
                        :disabled="disabledAdd"
                        class="btn-editprofile btn btn-outline-success btn-block"
                      >
                        {{ $t("save") }}
                      </button>
                    </div>
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        image: null,
      },
      isInvalid: false,
      disabledAdd: false,
      id: null,
    };
  },

  methods: {
    addImage1(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.body.image = files[0];
      this.$file2base64(e, (image) => {
        this.image = image;
      });
    },
    save() {
      if (this.body.userName && this.body.email && this.disabledAdd == false) {
        this.disabledAdd = true;
        if (
          !this.body.image||
          !this.body.image.endsWith(".jpg")||
          !this.body.image.endsWith(".png")
        ) {
          delete this.body.image;
        }
        this.$http.put("users/" + this.id, this.body).then(
          (res) => {
            this.body.image = res.data.image;
            this.$router.push("/myprofile");
            this.disabledAdd = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("Done Successfully"),
              detail: this.$t("Updated Successfully"),
              life: 3000,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      const body = res.data;
      this.body.userName = body.userName;
      this.body.email = body.email;
      this.body.phone = body.phone;
      this.body.image = body.image;
      this.id = body._id;
    });
  },
};
</script>
